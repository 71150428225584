import React, { Component } from 'react';

  import '../components/callculator.css';
  import { Form, ProgressBar, Button, InputGroup } from 'react-bootstrap';
  import PDF from '../components/atmsfy-privacy-policy.pdf';
  import {Helmet} from "react-helmet";
  import HU from '../components/hu-HU_lang.js';
  import EN from '../components/en-EN_lang.js';
  import Layout from "../components/layout";
class Callculator extends Component{
  constructor(props){
    super(props)
    this.state={
      input: "",
      data:[],
      scrolling: true,
      progress: 0,
      teamMembers: 0,
      ticketPercentage: 5,
      standardReq: 0,
      tickets: 0,
      cost1FTE: 0,
      firstName: "",
      lastName: "",
      email: "",
      finished: false
    }
    this.text1 = React.createRef();
    this.text2 = React.createRef();
    this.text3 = React.createRef();
    this.text4 = React.createRef();
    this.text5 = React.createRef();
    this.text6 = React.createRef();
    this.text7 = React.createRef();
    this.text8 = React.createRef();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.addEventListener('scroll', () => {
      const scrolling = window.scrollY < 100;
      if (scrolling !== this.state.scrolling) {
          this.setState({ scrolling })
      }
    });
  }
  onChange = (e) =>{
    e.preventDefault();    
    this.setState({
      teamMembers: this.text1.current.value,
      ticketPercentage: this.text2.current.value,
      standardReq: this.text3.current.value,
      tickets: this.text4.current.value,
      cost1FTE: this.text5.current.value,
      firstName: this.text6.current.value,
      lastName: this.text7.current.value,
      email: this.text8.current.value
    })
  }
  submitRoi = async (e) => {
    e.preventDefault();
    const token = "Bearer "+ process.env.REACT_APP_API_KEY
    //console.log(token);
    const response = await fetch("https://blingdy.app/hello/callculate", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "authorization": token
          },
        body: JSON.stringify({ 
          teammembers: this.state.teamMembers,
          tickets: this.state.tickets,
          standardReq: this.state.standardReq,
          workHours: this.state.ticketPercentage,
          costForOneYear: this.state.cost1FTE,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          emailAddr: this.state.email
        }),
      });
      const body = await response.json();
      console.log(body);
      this.setState({finished: true});
      window.location.reload(true);
}

render(){
  const getRedirectLanguage = () => {
    if (typeof navigator === `undefined`) {
      return "hu-HU";
    }
  
    const lang = navigator && navigator.language && navigator.language.split("-")[0];
    if (!lang) return "en";
  
    switch (lang) {
      case "hu":
        return "hu-HU";
      default:
        return "en";
    }
  };
  //console.log(this.state.scrolling);
  var userLang = getRedirectLanguage();
    return(
        <Layout>
      <div className={this.state.scrolling ? "otherPageDiv" : "otherPageDivScrolling"}>
      <div className="titleDiv">
        <Helmet>
          <title>Kalkuláljon ROI-t és megtérülést!</title>
          <meta name="description" content="Bizonytalan az automatizálás új eszközével kapcsolatban? Számítsuk ki a megtérülést az Automeasify segítségével, és jobban áttekinthessük lehetőségeit az # 1 IT Üzemeltetés Szolgáltatás Automatizáló eszközzel." />
          <meta property="og:title" content="Kalkuláljon ROI-t és megtérülést!" />
          <meta property="og:type" content="website" />
          <meta property="og:description " content="Bizonytalan az automatizálás új eszközével kapcsolatban? Számítsuk ki a megtérülést az Automeasify segítségével, és jobban áttekinthessük lehetőségeit az # 1 IT Üzemeltetés Szolgáltatás Automatizáló eszközzel." />
          <meta property="og:url" content="https://automeasify.com/kalkulator" />
          <meta property="og:image" content="https://blingdy.app/uploads/banner123_9558ffb79f.png" />
        </Helmet>
      
    <h2>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.calculatorHead : EN.calculatorHead}</h2>
      </div>
      <div className="contentDiv">
        {this.state.finished ? <div className="contentDivFyler5"><h2>Thank you for using our ROI callculator! Email will be in your inbox soon.</h2><br></br><br></br><br></br><br></br><br></br><br></br><br></br></div> : <div className="contentDivFyler5">
          <div className="progBar"><ProgressBar now={this.state.progress} label={`${this.state.progress}%`} /></div>
          <Form onSubmit={this.submitRoi}>
            <h4>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.calculatorContent : EN.calculatorContent}</h4><br></br><br></br>
            <Form.Group controlId="formBasicNumber">
              <Form.Label>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.calcFormLabel1 : EN.calcFormLabel1}</Form.Label>
              <Form.Control type="number" value={this.state.teamMembers} 
               ref={this.text1} onChange={this.onChange} placeholder="Number of IT Operations employes" size="lg" required/>
              <Form.Text className="text-muted">
              {userLang === "hu-HU" || userLang === "hu-hu" ? HU.calcFormDescript1 : EN.calcFormDescript1}
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="exampleForm.SelectCustomSizeSm">
              <Form.Label>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.calcFormLabel2 : EN.calcFormLabel2}</Form.Label>
              <Form.Control as="select" size="lg" custom 
              ref={this.text2} onChange={this.onChange} required>
                <option value="5">5%</option>
                <option value="10">10%</option>
                <option value="15">15%</option>
                <option value="20">20%</option>
                <option value="25">25%</option>
                <option value="30">30%</option>
                <option value="35">35%</option>
                <option value="40">40%</option>
                <option value="45">45%</option>
                <option value="50">50%</option>
                <option value="55">55%</option>
                <option value="60">60%</option>
                <option value="65">65%</option>
                <option value="70">70%</option>
                <option value="75">75%</option>
                <option value="80">80%</option>
                <option value="85">85%</option>
                <option value="90">90%</option>
                <option value="95">95%</option>
                <option value="100">100%</option>
              </Form.Control>
              <Form.Text className="text-muted">
              {userLang === "hu-HU" || userLang === "hu-hu" ? HU.calcFormDescript2 : EN.calcFormDescript2}
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="exampleForm.SelectCustomSizeSm">
              <Form.Label>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.calcFormLabel3 : EN.calcFormLabel3}</Form.Label>
              <Form.Control as="select" size="lg" ref={this.text3} onChange={this.onChange} custom required>
                <option value="5">5%</option>
                <option value="10">10%</option>
                <option value="15">15%</option>
                <option value="20">20%</option>
                <option value="25">25%</option>
                <option value="30">30%</option>
                <option value="35">35%</option>
                <option value="40">40%</option>
                <option value="45">45%</option>
                <option value="50">50%</option>
                <option value="55">55%</option>
                <option value="60">60%</option>
                <option value="65">65%</option>
                <option value="70">70%</option>
                <option value="75">75%</option>
                <option value="80">80%</option>
                <option value="85">85%</option>
                <option value="90">90%</option>
                <option value="95">95%</option>
                <option value="100">100%</option>
              </Form.Control>
              <Form.Text className="text-muted">
              {userLang === "hu-HU" || userLang === "hu-hu" ? HU.calcFormDescript3 : EN.calcFormDescript3}
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicNumber">
              <Form.Label>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.calcFormLabel4 : EN.calcFormLabel4}</Form.Label>
                <Form.Control type="number" placeholder="Number of Tickets per week" size="lg" value={this.state.tickets} 
               ref={this.text4} onChange={this.onChange} required/>
              <Form.Text className="text-muted">
              {userLang === "hu-HU" || userLang === "hu-hu" ? HU.calcFormDescript4 : EN.calcFormDescript4}
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicNumber">
              <Form.Label>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.calcFormLabel5 : EN.calcFormLabel5}</Form.Label>
              <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">HUF</InputGroup.Text>
                  <Form.Control  type="number" placeholder="Cost of 1 year FTE" size="lg" aria-describedby="inputGroupPrepend" value={this.state.cost1FTE} 
               ref={this.text5} onChange={this.onChange} required/>
                </InputGroup.Prepend>
              
              <Form.Text className="text-muted">
              {userLang === "hu-HU" || userLang === "hu-hu" ? HU.calcFormDescript5 : EN.calcFormDescript5}
              </Form.Text>
            </Form.Group>
            

            <Form.Group controlId="formBasicText">
              <Form.Label>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxInput1 : EN.contactBoxInput1}</Form.Label>
                <Form.Control type="text" placeholder={userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxInput1 : EN.contactBoxInput1} size="lg" value={this.state.firstName} 
               ref={this.text6} onChange={this.onChange} required/>
              <Form.Text className="text-muted">
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="formBasicText">
              <Form.Label>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxInput2 : EN.contactBoxInput2}</Form.Label>
                <Form.Control type="text" placeholder={userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxInput2 : EN.contactBoxInput2} size="lg" value={this.state.lastName} 
               ref={this.text7} onChange={this.onChange} required/>
              <Form.Text className="text-muted">
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxInput3 : EN.contactBoxInput3}</Form.Label>
                <Form.Control type="email" placeholder={userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxInput3 : EN.contactBoxInput3} size="lg" value={this.state.email} 
               ref={this.text8} onChange={this.onChange} required/>
              <Form.Text className="text-muted">
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="formBasicCheckbox">
                <Form.Check type="checkbox">
                <Form.Check.Input type="checkbox" required/>
                <Form.Check.Label>
                {userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxText1 : EN.contactBoxText1} <a href={PDF} target="_blank"><b>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxText2 : EN.contactBoxText2}</b></a> {userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxText4 : EN.contactBoxText4} <a href={PDF} target="_blank"><b>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxText3 : EN.contactBoxText3}</b></a>        
                </Form.Check.Label>
                </Form.Check>
              </Form.Group>
              <Button variant="primary" type="submit" >
              {userLang === "hu-HU" || userLang === "hu-hu" ? HU.calcButton : EN.calcButton}
            </Button> 
          </Form>
        </div>}
      </div>
  </div>
  </Layout>
    );
  }
}
export default Callculator